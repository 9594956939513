@import "_responsive";

@include for-tablet-portrait-up {
  /*   
  ::-webkit-scrollbar {
    width: 10px;
  }

  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */
}

.page-content {
  position: relative;
  .arrow {
    position: absolute;
    top: 0.7rem;
    right: 0;
    display: flex;
    img {
      width: 30px;
      height: 30px;
      height: auto;
    }
    @include for-phone-only {
      top: 0;
    }
  }
  .page-title {
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      text-align: center;
      color: #ffffff;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.01),
        rgba(255, 255, 255, 0.5)
      );
      padding: 1rem;
      border-radius: 20px;
      font-size: 1.3rem;

      @include for-phone-only {
        border-radius: 0;
        padding: 0;
        background-image: none;
      }
    }
  }
  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
    position: relative;
    @include for-phone-only {
      padding: 0;
    }
    .content-image {
      margin-right: 6.25rem;

      img {
        width: 150px;
        height: auto;
      }
      @include for-phone-only {
        display: none;
      }
      &.for-small-image {
        margin-right: 0;
        img {
          width: 250px;
          height: auto;
        }
      }
    }
    .content-desc {
      .overflow {
        position: relative;
        overflow-y: auto;
        padding-right: 0.5rem;
        @include for-phone-only {
          overflow-y: auto;
          padding-right: 0;
          height: 100%;
        }
        .no-scroll-zoom {
          overflow: hidden !important;
          overflow-y: hidden !important;
          padding-right: 0;
          height: 400px;
          margin-top: -80px;
        }
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      max-height: 60vh;
      min-width: 70%;
      padding: 0rem 0;
      box-sizing: border-box;

      .content-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
        color: #ffffff;
      }

      @include for-phone-only {
        height: 100%;
        max-height: auto;
        overflow: auto;
      }

      p {
        padding: 0.5rem 0;
        font-size: 1.1rem;
        line-height: 1.5rem;
        color: #ffffff;
        strong {
          font-weight: 800;
        }
      }
      .dots {
        position: absolute;
        bottom: 0;
        padding: 20px 0;
        width: 100%;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .dot {
          cursor: pointer;
          border: 1px solid #ffffff;
          /* background-color: #ffffff; */
          height: 5px;
          width: 50px;
          margin-right: 12px;
          &.active {
            background-color: #ffffff;
          }
        }
        @include for-phone-only {
          position: relative;
        }
      }

      // one container two column. include profile image, name, status and description
      .profiles {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        margin-bottom: 1rem;
        padding-left: 0.5rem;

        @include for-phone-only {
          grid-template-columns: 1fr;
        }

        .profile {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-bottom: 1rem;
          padding: 0.5rem 0;
          border-radius: 20px;
          padding: 1rem 1rem;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          height: auto;
          overflow: auto;

          .profile-content {
            padding-left: 1rem;
          }
          .profile-image {
            margin-bottom: 1rem;
            img {
              border-radius: 50%;
              width: 100px;
              height: auto;
            }
          }
          .profile-name {
            font-size: 1.5rem;
            font-weight: bold;

            color: #ffffff;
          }
          .profile-status {
            font-size: 0.8rem;
            line-height: 1.5rem;
            color: #ffffff;
            strong {
              font-weight: 800;
            }
          }
          .profile-description {
            p {
              font-size: 0.8rem;
              line-height: normal;
              color: #ffffff;
              strong {
                font-weight: 800;
              }
            }
          }
        }
      }
      // add questions. question contain one column. include question and answer, question icon.
      .questions {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        margin-bottom: 1rem;
        padding-left: 1px;        

        @include for-phone-only {
          grid-template-columns: 1fr;
        }
        .question {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          font-weight: 600;
          color: #ffffff;

          padding: 1rem 0;
          border-radius: 20px;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          padding: 1rem 1rem;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          height: auto;
          overflow: auto;

          .question-icon {
            cursor: pointer;
          }
        }
        .question-answer {
          margin-top: -1rem;
          padding: 1rem 1rem;
          background-color: #ffffff;
          color: #c7a053;
          line-height: 1.2rem;
          border-radius: 20px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          display: none;
          &.active {
            display: block;
          } 
        }
      }

      .contacts {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        margin-bottom: 1rem;

        @include for-phone-only {
          grid-template-columns: 1fr;
        }
        .contact-info {
        }
        .form-group {
          display: flex;
          flex-direction: column;
        }
        .contact-form {
          // contact form style contain name, email, phone, message, submit button.
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 0.5rem;
          padding: 1rem 0rem;
          border-radius: 20px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          height: auto;
          overflow: auto;

          .contact-form-title {
            font-size: 1rem;
            margin-bottom: 0.3rem;
            color: #ffffff;
            text-align: center;
          }
          .contact-form-input {
            margin-bottom: 1rem;
            font-size: 1.1rem;
            line-height: 1.5rem;
            color: #000000;
            padding: 0.5rem 0.5rem;
            border-radius: 10px;
            border: 1px solid #ffffff;
            background-color: #ffffff;

            //placeholder color change
            &::placeholder {
              color: #ef7676;
            }
          }
          .contact-form-submit {
            /* button style */
            background-color: #c33636;
            color: #ffffff;
            font-size: 1.1rem;
            font-weight: bold;
            padding: 0.5rem 1rem;
            border-radius: 20px;
            border: 0px solid #ffffff;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
              background-color: #ffffff;
              color: #c33636;
            }
          }
        }
      }

      .translator-form {
        display: flex;
        flex-wrap: wrap;

        margin-bottom: 1rem;

        .input-group {
          flex: 30%;
          padding-right: 20px;
          display: flex;
          flex-direction: column;
          .input-group-label {
            color: #ffffff;
            font-size: 1.1rem;
            font-weight: bold;
            padding: 0.5rem 0;
          }
          .input-group-item {
            margin-bottom: 1rem;
            font-size: 1.1rem;
            line-height: 1.5rem;
            color: #000000;
            padding: 0.5rem 0.5rem;
            border-radius: 10px;
            border: 1px solid #ffffff;
            background-color: #ffffff;

            //placeholder color change
            &::placeholder {
              color: #ef7676;
            }
          }
          .input-group-select {
            margin-bottom: 1rem;
            font-size: 1.1rem;
            line-height: 1.5rem;
            color: #000000;
            padding: 0.5rem 0.5rem;
            border-radius: 10px;
            border: 1px solid #ffffff;
            background-color: #ffffff;

            //placeholder color change
            &::placeholder {
              color: #ef7676;
            }
          }
          .input-group-item-button {
            margin-top: 2.5rem;

            background-color: #c33636;
            color: #ffffff;
            font-size: 1.1rem;
            font-weight: bold;
            padding: 0.5rem 1rem;
            border-radius: 20px;
            border: 0px solid #ffffff;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
              background-color: #ffffff;
              color: #c33636;
            }
          }
        }
      }
    }
  }
}
