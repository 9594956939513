@import "_responsive";

$home-square-size: 75vh;

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  .home-menu {
    height: $home-square-size;
    width: $home-square-size;
    border: 1px solid #ffffff;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;

    @include for-phone-only {
      height: auto;
    }

    @include for-phone-only {
      border: none;
      border-radius: none;
    }

    .dot {
      position: absolute;
      top: -15px;
      left: calc(50% - 15px);
      width: 30px;
      height: 30px;
      border-radius: 20px;
      transition: all 1s ease;
      /* background-color: var(--blue-color); */
      border: 1px solid #ffffff;

      @include for-phone-only {
        display: none;
      }

      &.active {
        background-color: #ffffff;
      }

      &:nth-child(2) {
        top: calc(50% - 15px);
        left: calc(100% - 15px);
      }
      &:nth-child(3) {
        top: calc(100% - 15px);
        left: calc(50% - 15px);
      }
      &:nth-child(4) {
        top: calc(50% - 15px);
        left: -15px;
      }

      &:hover {
        background-color: #ffffff;
        transition: all 0.2s ease-in;
      }
    }
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      @include for-phone-only {
        flex-direction: column;
      }

      li {
        display: none;
        justify-content: center;
        text-align: center;
        overflow: hidden;
        max-width: calc($home-square-size - 150px);
        min-height: 50%;

        @include for-phone-only {
          display: flex;
          flex-direction: column;
          overflow: auto;
          height: auto;
          min-height: 0;
          padding: 10% 0;
        }

        a {
          text-decoration: none;
        }
        &.active {
          display: flex;
          transition: all 1s ease-in;
        }
        .home-menu-image {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 75%;
            height: auto;
            object-fit: contain;
            object-position: center center;
          }
        }
        .home-menu-title {
          color: #ffffff;
          font-weight: bold;
          font-size: 2.5rem;
        }
        .home-menu-desc {
          position: relative;
          color: #ffffff;
          font-size: 1.1rem;
          line-height: 1.5rem;                
          img {     
            bottom: -5%;  
            position: absolute;               
            width: 32px;
            height: 32px;
          }    
        }
      }
    }
  }
}
