@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,600;1,700;1,800;1,900&display=swap";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: none;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --blue-color: #88cbff;
  --orange-color: #f5d300;
  --green-color: #61cd64;
  --pink-color: #ea817f;
}



html, body {
  width: 100%;
  height: 100vh;
  font-family: Poppins, sans-serif;
  overflow: hidden;
}

@media (max-width: 599px) {
  html, body {
    height: auto;
    overflow: auto;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.page {
  height: 80vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.blue {
  background-color: var(--blue-color);
  transition: all 1s;
}

.blue .dot {
  background-color: var(--blue-color);
}

.orange {
  background-color: var(--orange-color);
  transition: all 1s;
}

.orange .dot {
  background-color: var(--orange-color);
}

.green {
  background-color: var(--green-color);
  transition: all 1s;
}

.green .dot {
  background-color: var(--green-color);
}

.pink {
  background-color: var(--pink-color);
  transition: all 1s;
}

.pink .dot {
  background-color: var(--pink-color);
}

.header {
  height: 15vh;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (max-width: 599px) {
  .header {
    height: auto;
    flex-direction: column;
  }
}

.header .logo {
  margin-top: 1.8%;
}

@media (max-width: 599px) {
  .header .logo {
    padding: 20px 0;
  }
}

.header .logo img {
  max-width: 160px;
  height: auto;
}

.header .menu {
  background-image: linear-gradient(to right, #ffffff03, #ffffff80);
  border-radius: 2.5rem;
  padding: 1.25rem .625rem 1.25rem 1.5rem;
}

.header .menu ul {
  display: flex;
}

.header .menu ul li {
  margin-right: 1rem;
}

.header .menu ul li:first-child:after {
  content: "";
  height: 150%;
  opacity: .4;
  color: #fff;
  border-right: 2px solid #fff;
  margin-left: 1rem;
}

.header .menu ul li a {
  color: #fff;
  padding: 30px 0;
  text-decoration: none;
}

.header .menu ul li:hover a {
  color: #f0f0f0;
  transition: all .2s ease-in;
}

.sidebar {
  min-height: 100vh;
  width: 70px;
  z-index: 1;
  background-color: #fff3;
  justify-content: center;
  align-items: center;
  margin-top: -15vh;
  text-decoration: none;
  display: flex;
  position: fixed;
  right: 0;
}

.sidebar p {
  letter-spacing: 5px;
  color: #fff;
  font-size: 21px;
  transform: rotate(90deg);
}

.sidebar:after {
  color: #fff;
  letter-spacing: 0;
  content: "-";
  width: 50px;
  height: 0;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: -20%;
  transform: translateY(calc(-50% - 25px));
}

@media (max-width: 599px) {
  .sidebar {
    display: none;
  }
}

.footer {
  height: 5vh;
  align-items: flex-end;
  display: flex;
  position: fixed;
  bottom: .625rem;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 599px) {
  .footer {
    height: auto;
    flex-direction: column;
    position: relative;
  }
}

.footer .footer-area {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (max-width: 599px) {
  .footer .footer-area {
    flex-direction: column;
  }
}

@media (max-width: 599px) {
  .footer .footer-area .footer-menu {
    display: none;
  }
}

.footer .footer-area .footer-menu ul {
  align-items: center;
  display: flex;
}

.footer .footer-area .footer-menu ul li {
  margin-right: .5rem;
}

.footer .footer-area .footer-menu ul li:hover a {
  color: #f0f0f0;
  transition: all .2s ease-in;
}

.footer .footer-area .footer-menu ul li:after {
  content: "|";
  color: #fff;
  margin-left: .5rem;
}

.footer .footer-area .footer-menu ul li:last-child:after {
  content: "";
}

.footer .footer-area .footer-menu ul li a {
  color: #fff;
  font-size: .875rem;
  text-decoration: none;
}

.footer .footer-area .footer-social ul {
  display: flex;
}

.footer .footer-area .footer-social ul li {
  margin-left: 1rem;
}

.footer .footer-area .footer-social ul li a img {
  width: 30px;
  height: 30px;
}

.home {
  justify-content: center;
  align-items: center;
  display: flex;
}

.home .home-menu {
  height: 75vh;
  width: 75vh;
  border: 1px solid #fff;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  position: relative;
}

@media (max-width: 599px) {
  .home .home-menu {
    height: auto;
  }
}

@media (max-width: 599px) {
  .home .home-menu {
    border-radius: none;
    border: none;
  }
}

.home .home-menu .dot {
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 20px;
  transition: all 1s;
  position: absolute;
  top: -15px;
  left: calc(50% - 15px);
}

@media (max-width: 599px) {
  .home .home-menu .dot {
    display: none;
  }
}

.home .home-menu .dot.active {
  background-color: #fff;
}

.home .home-menu .dot:nth-child(2) {
  top: calc(50% - 15px);
  left: calc(100% - 15px);
}

.home .home-menu .dot:nth-child(3) {
  top: calc(100% - 15px);
  left: calc(50% - 15px);
}

.home .home-menu .dot:nth-child(4) {
  top: calc(50% - 15px);
  left: -15px;
}

.home .home-menu .dot:hover {
  background-color: #fff;
  transition: all .2s ease-in;
}

.home .home-menu ul {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 599px) {
  .home .home-menu ul {
    flex-direction: column;
  }
}

.home .home-menu ul li {
  text-align: center;
  max-width: calc(75vh - 150px);
  min-height: 50%;
  justify-content: center;
  display: none;
  overflow: hidden;
}

@media (max-width: 599px) {
  .home .home-menu ul li {
    height: auto;
    min-height: 0;
    flex-direction: column;
    padding: 10% 0;
    display: flex;
    overflow: auto;
  }
}

.home .home-menu ul li a {
  text-decoration: none;
}

.home .home-menu ul li.active {
  transition: all 1s ease-in;
  display: flex;
}

.home .home-menu ul li .home-menu-image {
  justify-content: center;
  align-items: center;
  display: flex;
}

.home .home-menu ul li .home-menu-image img {
  width: 75%;
  height: auto;
  object-fit: contain;
  object-position: center center;
}

.home .home-menu ul li .home-menu-title {
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
}

.home .home-menu ul li .home-menu-desc {
  color: #fff;
  font-size: 1.1rem;
  line-height: 1.5rem;
  position: relative;
}

.home .home-menu ul li .home-menu-desc img {
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: -5%;
}

.page-content {
  position: relative;
}

.page-content .arrow {
  display: flex;
  position: absolute;
  top: .7rem;
  right: 0;
}

.page-content .arrow img {
  width: 30px;
  height: 30px;
  height: auto;
}

@media (max-width: 599px) {
  .page-content .arrow {
    top: 0;
  }
}

.page-content .page-title {
  justify-content: center;
  align-items: center;
  display: flex;
}

.page-content .page-title h2 {
  text-align: center;
  color: #fff;
  background-image: linear-gradient(to right, #ffffff03, #ffffff80);
  border-radius: 20px;
  padding: 1rem;
  font-size: 1.3rem;
}

@media (max-width: 599px) {
  .page-content .page-title h2 {
    background-image: none;
    border-radius: 0;
    padding: 0;
  }
}

.page-content .content-container {
  justify-content: center;
  align-items: center;
  padding: 5% 0;
  display: flex;
  position: relative;
}

@media (max-width: 599px) {
  .page-content .content-container {
    padding: 0;
  }
}

.page-content .content-container .content-image {
  margin-right: 6.25rem;
}

.page-content .content-container .content-image img {
  width: 150px;
  height: auto;
}

@media (max-width: 599px) {
  .page-content .content-container .content-image {
    display: none;
  }
}

.page-content .content-container .content-image.for-small-image {
  margin-right: 0;
}

.page-content .content-container .content-image.for-small-image img {
  width: 250px;
  height: auto;
}

.page-content .content-container .content-desc {
  max-height: 60vh;
  min-width: 70%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0;
  display: flex;
}

.page-content .content-container .content-desc .overflow {
  padding-right: .5rem;
  position: relative;
  overflow-y: auto;
}

@media (max-width: 599px) {
  .page-content .content-container .content-desc .overflow {
    height: 100%;
    padding-right: 0;
    overflow-y: auto;
  }
}

.page-content .content-container .content-desc .overflow .no-scroll-zoom {
  height: 400px;
  margin-top: -80px;
  padding-right: 0;
  overflow: hidden !important;
}

.page-content .content-container .content-desc .content-title {
  color: #fff;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

@media (max-width: 599px) {
  .page-content .content-container .content-desc {
    height: 100%;
    max-height: auto;
    overflow: auto;
  }
}

.page-content .content-container .content-desc p {
  color: #fff;
  padding: .5rem 0;
  font-size: 1.1rem;
  line-height: 1.5rem;
}

.page-content .content-container .content-desc p strong {
  font-weight: 800;
}

.page-content .content-container .content-desc .dots {
  width: 100%;
  height: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  display: flex;
  position: absolute;
  bottom: 0;
}

.page-content .content-container .content-desc .dots .dot {
  cursor: pointer;
  height: 5px;
  width: 50px;
  border: 1px solid #fff;
  margin-right: 12px;
}

.page-content .content-container .content-desc .dots .dot.active {
  background-color: #fff;
}

@media (max-width: 599px) {
  .page-content .content-container .content-desc .dots {
    position: relative;
  }
}

.page-content .content-container .content-desc .profiles {
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;
  padding-left: .5rem;
  display: grid;
}

@media (max-width: 599px) {
  .page-content .content-container .content-desc .profiles {
    grid-template-columns: 1fr;
  }
}

.page-content .content-container .content-desc .profiles .profile {
  height: auto;
  border-radius: 20px;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  overflow: auto;
  box-shadow: 0 0 10px #0000001a;
}

.page-content .content-container .content-desc .profiles .profile .profile-content {
  padding-left: 1rem;
}

.page-content .content-container .content-desc .profiles .profile .profile-image {
  margin-bottom: 1rem;
}

.page-content .content-container .content-desc .profiles .profile .profile-image img {
  width: 100px;
  height: auto;
  border-radius: 50%;
}

.page-content .content-container .content-desc .profiles .profile .profile-name {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
}

.page-content .content-container .content-desc .profiles .profile .profile-status {
  color: #fff;
  font-size: .8rem;
  line-height: 1.5rem;
}

.page-content .content-container .content-desc .profiles .profile .profile-status strong {
  font-weight: 800;
}

.page-content .content-container .content-desc .profiles .profile .profile-description p {
  color: #fff;
  font-size: .8rem;
  line-height: normal;
}

.page-content .content-container .content-desc .profiles .profile .profile-description p strong {
  font-weight: 800;
}

.page-content .content-container .content-desc .questions {
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;
  padding-left: 1px;
  display: grid;
}

@media (max-width: 599px) {
  .page-content .content-container .content-desc .questions {
    grid-template-columns: 1fr;
  }
}

.page-content .content-container .content-desc .questions .question {
  color: #fff;
  height: auto;
  border-radius: 20px 20px 0 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  font-weight: 600;
  display: flex;
  overflow: auto;
  box-shadow: 0 0 10px #0000001a;
}

.page-content .content-container .content-desc .questions .question .question-icon {
  cursor: pointer;
}

.page-content .content-container .content-desc .questions .question-answer {
  color: #c7a053;
  background-color: #fff;
  border-radius: 0 0 20px 20px;
  margin-top: -1rem;
  padding: 1rem;
  line-height: 1.2rem;
  display: none;
}

.page-content .content-container .content-desc .questions .question-answer.active {
  display: block;
}

.page-content .content-container .content-desc .contacts {
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;
  display: grid;
}

@media (max-width: 599px) {
  .page-content .content-container .content-desc .contacts {
    grid-template-columns: 1fr;
  }
}

.page-content .content-container .content-desc .contacts .form-group {
  flex-direction: column;
  display: flex;
}

.page-content .content-container .content-desc .contacts .contact-form {
  height: auto;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: .5rem;
  padding: 1rem 0;
  display: flex;
  overflow: auto;
  box-shadow: 0 0 10px #0000001a;
}

.page-content .content-container .content-desc .contacts .contact-form .contact-form-title {
  color: #fff;
  text-align: center;
  margin-bottom: .3rem;
  font-size: 1rem;
}

.page-content .content-container .content-desc .contacts .contact-form .contact-form-input {
  color: #000;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: .5rem;
  font-size: 1.1rem;
  line-height: 1.5rem;
}

.page-content .content-container .content-desc .contacts .contact-form .contact-form-input::placeholder {
  color: #ef7676;
}

.page-content .content-container .content-desc .contacts .contact-form .contact-form-submit {
  color: #fff;
  cursor: pointer;
  background-color: #c33636;
  border: 0 solid #fff;
  border-radius: 20px;
  padding: .5rem 1rem;
  font-size: 1.1rem;
  font-weight: bold;
  transition: all .3s ease-in-out;
}

.page-content .content-container .content-desc .contacts .contact-form .contact-form-submit:hover {
  color: #c33636;
  background-color: #fff;
}

.page-content .content-container .content-desc .translator-form {
  flex-wrap: wrap;
  margin-bottom: 1rem;
  display: flex;
}

.page-content .content-container .content-desc .translator-form .input-group {
  flex-direction: column;
  flex: 30%;
  padding-right: 20px;
  display: flex;
}

.page-content .content-container .content-desc .translator-form .input-group .input-group-label {
  color: #fff;
  padding: .5rem 0;
  font-size: 1.1rem;
  font-weight: bold;
}

.page-content .content-container .content-desc .translator-form .input-group .input-group-item {
  color: #000;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: .5rem;
  font-size: 1.1rem;
  line-height: 1.5rem;
}

.page-content .content-container .content-desc .translator-form .input-group .input-group-item::placeholder {
  color: #ef7676;
}

.page-content .content-container .content-desc .translator-form .input-group .input-group-select {
  color: #000;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: .5rem;
  font-size: 1.1rem;
  line-height: 1.5rem;
}

.page-content .content-container .content-desc .translator-form .input-group .input-group-select::placeholder {
  color: #ef7676;
}

.page-content .content-container .content-desc .translator-form .input-group .input-group-item-button {
  color: #fff;
  cursor: pointer;
  background-color: #c33636;
  border: 0 solid #fff;
  border-radius: 20px;
  margin-top: 2.5rem;
  padding: .5rem 1rem;
  font-size: 1.1rem;
  font-weight: bold;
  transition: all .3s ease-in-out;
}

.page-content .content-container .content-desc .translator-form .input-group .input-group-item-button:hover {
  color: #c33636;
  background-color: #fff;
}



/*# sourceMappingURL=about.c475adbc.css.map */
