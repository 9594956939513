@import "_responsive";

.footer {
  /* padding: 5rem 0 1rem 0; */
  position: fixed;
  bottom: 0.625rem;
  left: 50%;
  transform: translateX(-50%);
  height: 5vh;
  display: flex;
  align-items: flex-end;

  @include for-phone-only {
    position: relative;    
    height: auto;
    flex-direction: column;
  }


  .footer-area {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include for-phone-only {      
      flex-direction: column;
    }

    .footer-menu {
      @include for-phone-only {      
        display: none;
      }
      ul {
        display: flex;
        align-items: center;
        li {
          margin-right: 0.5rem;
          &:hover {
            a {
              color: rgb(240, 240, 240);
              transition: all 0.2s ease-in;
            }
          }
          &::after {
            content: "|";
            color: #ffffff;
            margin-left: 0.5rem;
          }
          &:last-child {
            &::after {
              content: "";
            }
          }
          a {
            font-size: 0.875rem;
            text-decoration: none;
            color: #ffffff;
          }
        }
      }
    }
    .footer-social {
      ul {
        display: flex;
        li {
          margin-left: 1rem;
          a {
            img {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}
