@import "_responsive";

.header {
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include for-phone-only {
    flex-direction: column;
    height: auto;
  }

  .logo {
    margin-top: 1.8%;
    @include for-phone-only {
      padding: 20px 0;
    }
    img {
      max-width: 160px;
      height: auto;
    }
  }

  .menu {
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.01),
      rgba(255, 255, 255, 0.5)
    );
    padding: 1.25rem 0.625rem 1.25rem 1.5rem;
    border-radius: 2.5rem;

    ul {
      display: flex;

      li {
        margin-right: 1rem;

        &:first-child {
          &::after {
            content: "";
            height: 150%;
            opacity: 0.4;
            border-right: 2px solid #ffffff;
            color: #ffffff;
            margin-left: 1rem;
          }
        }

        a {
          text-decoration: none;
          color: #ffffff;
          padding: 30px 0px;
        }
        &:hover {
          a {
            color: rgb(240, 240, 240);
            transition: all 0.2s ease-in;
          }
        }
      }
    }
  }
}
.sidebar {
  margin-top: -15vh;
  position: fixed;
  right: 0;
  min-height: 100vh;
  width: 70px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  p {
    transform: rotate(90deg);
    letter-spacing: 5;
    font-size: 21px;
    color: #ffffff;
  }
  &::after {
    color: #ffffff;
    font-size: 50px;
    top: 50%;
    left: -20%;
    transform: translateY(calc(-50% - 25px));
    letter-spacing: 0;
    position: absolute;
    content: "-";
    width: 50px;
    height: 0px;
  }
  @include for-phone-only {
    display: none;
  }

}
