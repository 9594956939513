@import "_responsive";

html,
body {
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  width: 100%;
  height: 100vh;

  @include for-phone-only {
    height: auto;
    overflow: auto;
  }
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.blue {
  background-color: var(--blue-color);
  .dot {
    background-color: var(--blue-color);
  }
  transition: all 1s ease;
}
.orange {
  background-color: var(--orange-color);
  .dot {
    background-color: var(--orange-color);
  }
  transition: all 1s ease;
}
.green {
  background-color: var(--green-color);
  .dot {
    background-color: var(--green-color);
  }
  transition: all 1s ease;
}
.pink {
  background-color: var(--pink-color);
  .dot {
    background-color: var(--pink-color);
  }
  transition: all 1s ease;
}
