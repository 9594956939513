@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

@import url("_reset.scss");
@import url("_variable.scss");
@import url("_responsive.scss");
@import url("_general.scss");

/* @import url('_style'); */

@import url("_header.scss");
@import url("_footer.scss");

@import url("_home.scss");
@import url("_page.scss");


